<p-toast></p-toast>
@if(peritos.length > 0){
  <div class="title-accept mb-3">{{labelPeritosAceptados}}</div>
}
@for(item of peritos; track item.id;){
  <div class="perito-container">
      <div [ngClass]="isMobile ? 'perito-mobile-container' : ''" >
        <img [ngClass]="item.contratado == '1' ? 'img-perito' : 'img-perito pixelated'" src="assets/img/avatar.png" alt="WISER">
        @if(isMobile){
          <app-info-perito [item]="item" [onlyShowDescription]="false" [showDescription]="false"></app-info-perito>
        }
      </div>

      <div class="perito-info">
        @if(isMobile){
          <app-info-perito [item]="item" [onlyShowDescription]="true" [showDescription]="true"></app-info-perito>
          <app-info-perito-precio [item]="item"></app-info-perito-precio>
          <div class="tasaciones-info mt-1">
            <app-info-perito-tasaciones [item]="item"></app-info-perito-tasaciones>
            <app-stars [info]="item"></app-stars>
            @if(item.contratado == '1'){
              <p-button [disabled]="false" styleClass="btn-primary" label="{{labelContratado}}" (onClick)="showData(item)"></p-button>
            }@else{
              @if(!contratada){
                <p-button [disabled]="visible" styleClass="btn-primary" label="{{labelContratar}}" (onClick)="showModal()"></p-button>
                <app-modal [visible]="visible" [labelCancel]="labelCancelBtnModal" [labelOk]="labelAcceptBtnModal" [labelTitleModal]="labelTitleModal" [labelSubtitleModal]="labelSubtitleModal" (checkedAccept)="contratar(item)" (checkedCancel)="showModal()"></app-modal>
              }
            }
          </div>
        }@else{
          <app-info-perito [item]="item" [showDescription]="true"></app-info-perito>
          <div class="tasaciones-info mt-1">
            <app-info-perito-tasaciones [item]="item"></app-info-perito-tasaciones>
            <app-info-perito-precio [item]="item"></app-info-perito-precio>
            <app-stars [info]="item"></app-stars>
            @if(item.contratado == '1'){
              <p-button [disabled]="false" styleClass="btn-primary" label="{{labelContratado}}" (onClick)="showData(item)"></p-button>
            }@else{
              @if(!contratada){
                <p-button [disabled]="visible" styleClass="btn-primary" label="{{labelContratar}}" (onClick)="showModal()"></p-button>
                <app-modal [visible]="visible" [labelCancel]="labelCancelBtnModal" [labelOk]="labelAcceptBtnModal" [labelTitleModal]="labelTitleModal" [labelSubtitleModal]="labelSubtitleModal" (checkedAccept)="contratar(item)" (checkedCancel)="showModal()"></app-modal>
              }
            }
          </div>
        }

      </div>
  </div>
  <hr class="separator">
}

@if(peritos.length == 0){
  <div class="title-accept">{{labelLamentamos}}</div>
  <div class="mt-3 botonera-wrapper">
    <p-button styleClass="btn-primary-white mb-1 ml-1" (onClick)="cancelar()">
      <span class="px-3" style="color:#6366F1;">{{labelCancelarSolicitud}}</span>
    </p-button>
  </div>
}

