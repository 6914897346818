import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { environment } from '../../../../../../environment/environment';
import { TipologiaPipe } from '../../../../../core/pipes/tipologia.pipe';
import { ApiService } from '../../../../../core/services/api.service';
import { InfoPeritaje } from '../../../../../core/models/info-peritaje';

@Component({
  selector: 'app-ver-solicitud',
  standalone: true,
  imports: [
    ButtonModule,
    ToastModule,
    TipologiaPipe
  ],
  providers: [MessageService],
  templateUrl: './ver-solicitud.component.html',
  styleUrl: './ver-solicitud.component.scss'
})
export class VerSolicitudComponent implements OnInit {
  @Input() solicitudId: number = 0;
  @Input() contactoId: number = 0;
  @Output() finished = new EventEmitter();

  labelSi: string = 'Me interesa';
  labelNo: string = 'No me interesa';

  labelInscrito: string = 'Inscrito';
  labelContratado: string = 'Contratado';
  solicitud: InfoPeritaje = {
  id: 0,
  profesional:0,
  tipologia: 0,
  especialidad: 0,
  encargo: 0,
  direccion: '',
  lat: '',
  lng: '',
  localizado: false,
  fecha_inicio: '',
  fecha_fin: '',
  partido_judicial: '',
  numero_juzgado: '',
  dificultad: '',
  referencia_interna: '',
  cif: '',
  nombre_empresa: '',
  email_facturacion: '',
  email_notificacion: '',
  direccion_facturacion: '',
  guardar_datos_facturacion: false,
  instrucciones: '',
  archivos: '',
  procedimiento:  '',
  contratada: false,
  finalizada: false,
  user: '',
  created:  ''
 };
  labelLamentamos: string = 'Lo lamentamos pero de momento ningún perito ha aceptado tu solicitud.';
  labelModificarSolicitud: string = 'Modificar solicitud';
  labelCancelarSolicitud: string = 'Cancelar solicitud';

  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
  labelFechaEntrena: string = 'Fecha entrega';
  labelLugar: string = 'Lugar';
  titleEmail: string = 'Interesado en la solicitud';
  textEmail: string = 'Uno de nuestro profesionales está interesado en su solicitud. Por favor, acceda a la plataforma para revisar la solicitud';
  textBtnEmail: string = 'Acceder a la plataforma';
  URL_SERVER = environment.urlWiser;
  labelDocumentacion: string = 'Documentación adjunta';
  labelDescargar: string = 'Descargar';
  contratada: boolean = false;
  isSelected: string = '3';
  hasDocumentacion: boolean = false;
  emailContacto: string = '';

  constructor(private apiService: ApiService, private messageService: MessageService, private router: Router) {}

  ngOnInit(): void {
    this.isSelected = '3';
    this.hasDocumentacion = false;
    if (this.solicitudId !== 0){
      this.apiService.getPeritajeById(this.solicitudId.toString()).subscribe((data: InfoPeritaje | any) => {
          if(data){
            this.solicitud = data;
            this.emailContacto = this.solicitud.user;
          }
      });
      this.apiService.getSolicitudByPeritajeContacto(this.solicitudId, this.contactoId).subscribe((data: boolean | any) => {
          this.isSelected = data;
      });

      this.apiService.hasDocumentacion(this.solicitudId).subscribe((data: boolean | any) => {
        this.hasDocumentacion = data;
      });
    }
  }

  getDocumentacion(): void{
    this.apiService.getDocumentacion(this.solicitudId).subscribe((zipFile: Blob | any) => {
        // Handle the ZIP file
        const blobUrl = URL.createObjectURL(zipFile);
        window.open(blobUrl); // Open the ZIP file in a new tab for download
      },
      error => {
        console.error('Failed to create ZIP file:', error);
      }
    );
  }

  modificar(): void {

  }

  interesa(): void {
    this.apiService.setSolicitud(this.solicitudId, this.contactoId).subscribe((data) => {
      if(data){
        this.messageService.add({ severity: 'success', summary: 'Solicitud aceptada', detail: 'Solicitud aceptada.', life: 3000 });
        this.finished.emit(true);
        this.apiService.sendEmail(this.titleEmail, this.textEmail, this.emailContacto, this.textBtnEmail, this.URL_SERVER + '/login').subscribe((dataEmail) => {
          if(dataEmail){
            //console.log('hola');
          }
        });
      }else{
        this.messageService.add({ severity: 'error', summary: 'Solicitud aceptada', detail: 'No se ha podido aceptar la solicitud.', life: 3000 });
      }
    },
    (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
    });
  }

  nointeresa(): void {
    this.apiService.setSolicitud(this.solicitudId, this.contactoId, 2).subscribe((data) => {
      if(data){
        this.messageService.add({ severity: 'success', summary: 'Solicitud aceptada', detail: 'Solicitud aceptada.', life: 3000 });
        this.finished.emit(true);
      }else{
        this.messageService.add({ severity: 'error', summary: 'Solicitud aceptada', detail: 'No se ha podido aceptar la solicitud.', life: 3000 });
      }
    },
    (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
    });
  }
}
