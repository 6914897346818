import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderWizardComponent } from '../../../layout/header-wizard/header-wizard.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BotoneraWizardComponent } from '../../../layout/botonera-wizard/botonera-wizard.component';
import { FooterComponent } from '../../../layout/footer/footer.component';
import { WizardService } from '../../../core/services/wizard.service';
import { Peritaje } from '../../../core/models/peritaje';
import { FieldMandatoryComponent } from '../../../layout/field-mandatory/field-mandatory.component';
import { ApiService } from '../../../core/services/api.service';
import { NgxStripeModule, StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { loadStripe, Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { environment } from '../../../../environment/environment';

@Component({
  selector: 'app-pago',
  standalone: true,
  imports: [
    HeaderWizardComponent,
    FormsModule,
    ReactiveFormsModule,
    BotoneraWizardComponent,
    FooterComponent,
    FieldMandatoryComponent,
    NgxStripeModule
  ],
  providers: [StripeService],
  templateUrl: './pago.component.html',
  styleUrl: './pago.component.scss'
})
export class PagoComponent implements OnInit{

  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  labelImporte: string = '';
  labelNumeroTarjeta: string = 'Número de la tarjeta';
  labelExpira: string = 'Expira';
  labelCVC: string = 'CVC';
  private cardElement!: StripeCardElement;
  private elements!: StripeElements | null;
  private stripe!: Stripe | null;
  stripeElements: any;
  amount = 30;

  form: FormGroup  =  new FormGroup({
    numero: new FormControl('', [Validators.required]),
    expira: new FormControl('', [Validators.required]),
    cvc: new FormControl('', [Validators.required]),
  });
  submitted: boolean = false;


  constructor(public wizardService: WizardService, private router: Router, private apiService: ApiService,  private stripeService: StripeService){

  }

  async ngOnInit() {
    this.stripe = await loadStripe(environment.PK_STRIPE);
    if (!this.stripe) {
      console.error('Stripe.js failed to load.');
      return;
    }
 // Initialize Stripe Elements
    this.elements = this.stripe.elements({ locale: 'es' });
    // Create the Card Element and mount it
    this.cardElement = this.elements.create('card');
    this.cardElement.mount('#card-element');

    const peritaje: Peritaje = this.wizardService.getInformacion();
    const perfil = peritaje.information.perfil.profesional;
    let labelPerfil = 'peritos';
    if(perfil == 2){
      labelPerfil = 'abogados';
    }else if(perfil == 3){
      labelPerfil = 'procuradores';
    }

    this.labelImporte = 'Vamos a proceder <b>al cargo de 19€, en caso de que ninguno de nuestros ' + labelPerfil + ' pueda efectuar el informe, te devolveremos el dinero.</b>';
  }




  async pay() {
    if (!this.stripe) {
      console.error('Stripe.js is not initialized.');
      return;
    }
    if (this.form.valid) {
      this.apiService.createPaymentIntent(this.amount).subscribe( async (data: any) => {
        if (data) {
          const secret = data.client_secret;
          if (this.stripe) {
            // Create a payment method using the card element
            const { error: paymentMethodError, paymentMethod } = await this.stripe.createPaymentMethod({
              type: 'card',
              card: this.cardElement,
              billing_details: {
                name: environment.emailAdmin,
              }
            });

            if (paymentMethodError) {
              // Handle error with creating the payment method
              console.error(paymentMethodError);
              alert(paymentMethodError.message);
              return;
            }

            const { error: confirmError, paymentIntent } = await this.stripe.confirmCardPayment(secret, {
              payment_method: paymentMethod.id,
            });
            if (confirmError) {
              // Handle confirmation error
              console.error(confirmError);
              alert(confirmError.message);
            } else {
              // Payment was successful
              alert('Payment successful!');
              console.log('PaymentIntent:', paymentIntent);
              if (this.form.valid) {
                this.submitted = false;
                this.router.navigate(['/wizard/documentacion']);
              }
              this.router.navigate(['/wizard/confirmacion']);
            }

          }

        }
      })
    } else {
      console.log(this.form);
    }
  }

  next(): void {
    this.pay();
  }

  last(): void {
    this.submitted = true;
  }

  get f(){
    return this.form.controls;
  }
}
