export const environment = {
  production: false,
  company: 'WISER',
  baseUrl: 'https://wiser.canaldelinformante.com/backend',
  assetsUrl: 'https://wiser.canaldelinformante.com/assets',
  urlWiser: 'https://wiser.canaldelinformante.com/',
  emailAdmin: 'jesus.cdonaire@gmail.com',
  PK_STRIPE: 'pk_test_Dt4ZBItXSZT1EzmOd8yCxonL'
};

