<app-header-wizard></app-header-wizard>
<app-field-mandatory></app-field-mandatory>
<div class="pago-container mt-3">
  <span class="title-description mb-3 block" [innerHTML]="labelImporte"></span>
  <form [formGroup]="form">
    <div class="register-data-container">
      <div class="w-form">
        <!--<div class="w-100">
          <img class="img-bank" src="../../../../assets/img/banks.svg" alt="VISA/MASTERCARD" style="float:right;">
          <label for="numero" class="label-form">{{labelNumeroTarjeta}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="numero" pInputText formControlName="numero" placeholder="1234 1234 1234 1234"/>
          @if((f['numero'].touched || submitted) && (f['numero'].errors && f['numero'].errors['required'])){
            <span class="text-danger">El campo número de tarjeta es obligatorio</span>
          }
        </div>

        <div class="register-data-container">
          <div class="w-form">
            <label for="expira" class="label-form">{{labelExpira}}</label>
            <input type="text" class="custom-form w-100 mb-1 placeholder" id="expira" pInputText formControlName="expira" placeholder="MM/YY"/>
            @if((f['expira'].touched || submitted) && (f['expira'].errors && f['expira'].errors['required'])){
              <span class="text-danger">El campo expira es obligatorio</span>
            }
          </div>
          <div class="w-100">
            <label for="cvc" class="label-form">{{labelCVC}}</label>
            <input type="text" class="custom-form w-100 mb-1 placeholder" id="cvc" pInputText formControlName="cvc" placeholder="CVC"/>
            @if((f['cvc'].touched || submitted) && (f['cvc'].errors && f['cvc'].errors['required'])){
              <span class="text-danger">El campo empresa es obligatorio</span>
            }
          </div>
        </div>-->
        <div class="w-100">
          <img class="img-bank" src="../../../../assets/img/banks.svg" alt="VISA/MASTERCARD" style="float:right;">
          <label for="numero" class="label-form">{{labelNumeroTarjeta}}</label>
          <div id="card-element"></div>
        </div>
      </div>
    </div>
  </form>

  <app-botonera-wizard (nextClicked)="next()" (lastClicked)="last()"></app-botonera-wizard>
</div>

<app-footer></app-footer>
