import { Component, Input } from '@angular/core';
import { ContactoProcurador } from '../../../../../../core/models/contacto-procurador';
import { TipologiaPipe } from '../../../../../../core/pipes/tipologia.pipe';
import { SlicePipe } from '@angular/common';

@Component({
  selector: 'app-info-perito',
  standalone: true,
  imports: [
    TipologiaPipe,
    SlicePipe
  ],
  templateUrl: './info-perito.component.html',
  styleUrl: './info-perito.component.scss'
})
export class InfoPeritoComponent {
  @Input() item: ContactoProcurador = new ContactoProcurador();
  @Input() showDescription: boolean = true;
  @Input() onlyShowDescription: boolean = false;
  @Input() showName: boolean = false;
  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
}
