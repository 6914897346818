import { WizardService } from './../../../core/services/wizard.service';
import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../../../layout/header/header.component";
import { BotoneraWizardComponent } from "../../../layout/botonera-wizard/botonera-wizard.component";
import { HeaderWizardComponent } from "../../../layout/header-wizard/header-wizard.component";
import { NgClass } from '@angular/common';
import { FooterComponent } from "../../../layout/footer/footer.component";
import { Router } from '@angular/router';
import { FieldMandatoryComponent } from '../../../layout/field-mandatory/field-mandatory.component';

@Component({
    selector: 'app-perfil',
    standalone: true,
    templateUrl: './perfil.component.html',
    styleUrl: './perfil.component.scss',
    imports: [
        HeaderComponent,
        BotoneraWizardComponent,
        HeaderWizardComponent,
        FieldMandatoryComponent,
        NgClass,
        FooterComponent,
    ]
})
export class PerfilComponent implements OnInit{
  labelTitle: string = 'Dinos qué <b>perfil profesional necesitas</b>';
  labelPeritaje: string = 'Peritos';
  labelAbogado: string = 'Abogados';
  labelProcurador: string = 'Procuradores';
  txtPerito: string = 'Contamos con un equipo de más de 500 peritos para sustituirle en los señalamientos que no puede atender';
  txtAbogado: string = 'Contamos con un equipo de más de 500 letrados para sustituirle en los señalamientos que no puede atender';
  txtProcuradores: string = 'Contamos con un equipo de más de 500 letrados para sustituirle en los señalamientos que no puede atender';
  error: string = '';
  labelRequired: string = 'Es necesario seleccionar un perfil';
  perfiles: {id: number, name: string, url: string, description: string, play: string}[] = [
    {
      id: 1,
      name: this.labelPeritaje,
      url: 'perito.svg',
      description: this.txtPerito,
      play: 'bt-play-gray'
    },
    {
      id: 2,
      name: this.labelAbogado,
      url: 'abogada.svg',
      description: this.txtAbogado,
      play: 'bt-play-gray'
    },
    {
      id: 3,
      name: this.labelProcurador,
      url: 'procurador.svg',
      description: this.txtProcuradores,
      play: 'bt-play-gray'
    },
  ];

  perfil: number = 0;

  datosWizard  = {
    profesional: 0,
    tipologia: 0,
    especialidad: 0,
    encargo: 0
  };

  constructor(public wizardService: WizardService, private router: Router) {}

  ngOnInit(): void {
    this.datosWizard = this.wizardService.getInformacion().information.perfil;
    if (this.datosWizard){
      this.perfil = this.datosWizard.profesional;
    }
  }

  selectPerfil(first: number): void {
    this.perfil = first;
    this.perfiles.forEach(element => {
      if(element.id === first){
        element.play = 'bt-play-purple';
      }else{
        element.play = 'bt-play-gray';
      }
    });
    this.next();
  }

  next(): void {
    if (this.perfil === 0){
      this.error = this.labelRequired;
    }else{
      this.datosWizard.profesional = this.perfil;
      this.wizardService.informacion.information.perfil = this.datosWizard
      this.wizardService.complete();
      if(this.perfil === 3){ // PROCURADOR
        this.router.navigate(['wizard/ubicacion']);
      }else if(this.perfil === 2){ //ABOGADO SUSTITUCIÓN
        this.router.navigate(['wizard/encargo']);
       }else{
        this.router.navigate(['wizard/tipologia']);
      }
    }

  }

  last(): void {
   // this.router.navigate(['/wizard/documentacion']);
  }



}
