import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from '../../../core/services/api.service';
import { InfoOnboardingComponent } from '../../../layout/info-onboarding/info-onboarding.component';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { LogoComponent } from '../../../layout/logo/logo.component';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../../environment/environment';
import { ButtonModule } from 'primeng/button';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  providers: [MessageService],
  imports: [
    InfoOnboardingComponent,
    MessagesModule,
    ToastModule,
    LogoComponent,
    MessagesModule,
    ButtonModule,
    ReactiveFormsModule,
    NgClass
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {
  labelHelp: string = 'Si no recuerdas tu contraseña o tienes problemas para acceder, por favor escribe tu email y te mandaremos un enlace para poder recuperar tu contraseña';
  labelEmail: string = 'Dirección de correo electrónico';
  labelEnviar: string = 'Enviar';
  submitted: boolean = false;
  titleEmail: string = 'Olvido su contraseña';
  messageEmail: string = 'Recibes este correo porque has solicitado recuperar tu contraseña, confírmalo y generaremos otra contraseña'
  btnEmail: string = 'Generar otra contraseña';
  sendEmail: boolean = true;

  URL_SERVER = environment.urlWiser;

  form: FormGroup  = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(private apiService: ApiService, private router: Router, private messageService: MessageService){}

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  get f(){
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    this.sendEmail = false;
    if (this.form.valid){
      this.apiService.getPinByEmail(this.form.controls['email'].value).subscribe((data) => {
        if(data){
          this.apiService.sendEmail(this.titleEmail, this.messageEmail,this.form.controls['email'].value, this.btnEmail, this.URL_SERVER + '/password?email=' + this.form.controls['email'].value + '&pin=' + data).subscribe((dataEmail) => {
            this.sendEmail = true;
            if(dataEmail){
              this.messageService.add({ severity: 'success', summary: 'Generar contraseña', detail: 'Revisa tu bandeja de correo, si no ves ningún email nuestro por favor revisa también la bandeja de spam', life: 3000 });
              setTimeout(() => {
                this.router.navigate(['/help-login']);
              }, 1000);
            }else{
              this.messageService.add({ severity: 'error', summary: 'Generar contraseña', detail: 'No se ha podido enviar el correo electrónico. Póngase en contacto con soporte.', life: 3000 });
            }
          },
          (err) => {
            this.messageService.add({ severity: 'error', summary: 'Generar contraseña', detail: err, life: 3000 });
          });
        }
      });


    }
  }
}
