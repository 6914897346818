import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environment/environment';
import { ContactoProcurador } from '../models/contacto-procurador';
import { Peritaje } from '../models/peritaje';
import { PaymentIntent } from '@stripe/stripe-js';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  PHP_API_SERVER = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  login(username: string, password: string)  {
    return this.httpClient.post(`${this.PHP_API_SERVER}/login.php`, {
      email: username,
      password: password,
    });
  }

  register(name: string, email:string, password: string, accept_terms: boolean, accept_receive_emails: boolean) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/register.php`, {
      email: email,
      name: name,
      password: password,
      accept_terms: accept_terms,
      accept_receive_emails: accept_receive_emails
    });
  }

  updatePassword(username: string, password: string, pin: string)  {
    return this.httpClient.post(`${this.PHP_API_SERVER}/updatePassword.php`, {
      email: username,
      password: password,
      pin: pin,
    });
  }

  validateEmail(username: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/validateEmail.php`, {
      email: username,
    });
  }

  createPaymentIntent(amount: number): Observable<PaymentIntent> {
    return this.httpClient.post<PaymentIntent>(
      `${this.PHP_API_SERVER}/createPayment.php`, {
        amount: amount
      }
    );
  }

  sendEmail(title: string, message:string, email: string, titleBtn: string = '', urlBtn: string = '', sendFiles: string = '') {
    return this.httpClient.post(`${this.PHP_API_SERVER}/sendEmail.php`, {
      title: title,
      message: message,
      email: email,
      titleBtn: titleBtn,
      urlBtn: urlBtn,
      sendFiles: sendFiles
    });
  }

  upload(user: string, description: string){
    return this.httpClient.post(`${this.PHP_API_SERVER}/upload.php`, {
      user: user,
      description: description,
    });
  }

  getPinByEmail(email: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getPinByEmail.php`, {
      email: email,
    });
  }

  createContact(contact: ContactoProcurador) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/addContact.php`, {
      perito: contact.perito,
      abogado: contact.abogado,
      procurador: contact.procurador,
      nombre_completo: contact.nombre_completo,
      especializacion: contact.especializacion,
      subespecializacion: contact.subespecializacion,
      nombre_colegio: contact.nombre_colegio,
      numero_colegiado: contact.numero_colegiado,
      direccion: contact.direccion,
      codigo_postal: contact.codigo_postal,
      ciudad: contact.ciudad,
      telefono: contact.telefono,
      email: contact.email,
      anos_activo: contact.anos_activo,
      acepta_encargos: contact.acepta_encargos,
      nombre_despacho: contact.nombre_despacho,
      breve_descripcion: contact.breve_descripcion,
      precio: contact.precio,
      valoracion: contact.valoracion,
      metodos_pago: contact.metodos_pago,
      iban: contact.iban,
    });
  }

  updateContact(contact: ContactoProcurador, emailAdmin: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/updateUsuario.php`, {
      id: contact.id,
      perito: contact.perito,
      abogado: contact.abogado,
      procurador: contact.procurador,
      nombre_completo: contact.nombre_completo,
      especializacion: contact.especializacion,
      subespecializacion: contact.subespecializacion,
      nombre_colegio: contact.nombre_colegio,
      numero_colegiado: contact.numero_colegiado,
      direccion: contact.direccion,
      codigo_postal: contact.codigo_postal,
      ciudad: contact.ciudad,
      telefono: contact.telefono,
      email: contact.email,
      anos_activo: contact.anos_activo,
      acepta_encargos: contact.acepta_encargos,
      nombre_despacho: contact.nombre_despacho,
      breve_descripcion: contact.breve_descripcion,
      metodos_pago: contact.metodos_pago,
      precio: contact.precio,
      valoracion: contact.valoracion,
      iban: contact.iban,
      emailAdmin: emailAdmin,
    });
  }

  createPeritaje(peritaje: Peritaje, email: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/addPeritaje.php`, {
      profesional: peritaje.information.perfil.profesional,
      tipologia: peritaje.information.perfil.tipologia,
      especialidad: peritaje.information.perfil.especialidad,
      encargo: peritaje.information.perfil.encargo,

      direccion: peritaje.information.ubicacion.direccion,
      lat: peritaje.information.ubicacion.lat,
      lng: peritaje.information.ubicacion.lng,
      localizado: peritaje.information.ubicacion.localizado,
      fechaInicio: peritaje.information.ubicacion.fechaInicio,
      fechaFin: peritaje.information.ubicacion.fechaFin,
      partidoJudicial: peritaje.information.ubicacion.partidoJudicial,
      numeroJuzgado: peritaje.information.ubicacion.numeroJuzgado,
      dificultad: peritaje.information.ubicacion.dificultad,
      referenciaInterna: peritaje.information.ubicacion.referenciaInterna,

      cif: peritaje.information.datosFacturacion.CIF,
      nombreEmpresa: peritaje.information.datosFacturacion.nombreEmpresa,
      emailFacturacion: peritaje.information.datosFacturacion.emailFacturacion,
      emailNotificacion: peritaje.information.datosFacturacion.emailNotificacion,
      direccionFacturacion: peritaje.information.datosFacturacion.direccionFacturacion,
      guardarDatosFacturacion: peritaje.information.datosFacturacion.guardarDatosFacturacion,

      instrucciones: peritaje.information.documentacion.instrucciones,
      procedimiento: peritaje.information.documentacion.procedimiento,
      archivos: peritaje.information.documentacion.archivos,

      email: email
    });
  }

  getPeritajesByUserState(email: string, state: number, search: string = '') {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getPeritajesByUserState.php`, {
      email: email,
      state: state,
      search: search
    })
  }

  getPeritajeById(id: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getPeritajesById.php`, {
      id: id
    })
  }


  getAllPeritos() {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getAllPeritos.php`, {

    })
  }

  getAllPeritosByParams(type: string, especializacion: number, subespecializacion: number, encargo: number, ubicacion: string, localizado: boolean = false) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getAllPeritosByParams.php`, {
      type: type,
      especializacion: especializacion,
      subespecializacion: subespecializacion,
      encargo: encargo,
      ubicacion: ubicacion,
      localizado: localizado
    })
  }

  getAllPeritosBySolicitud(peritaje: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getAllPeritosBySolicitud.php`, {
      peritaje: peritaje
    })
  }

  getPeritoById(id: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getPeritoById.php`, {
      id: id,
    });
  }

  getPeritoByEmail(email: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getPeritoByEmail.php`, {
      email: email,
    });
  }

  setSolicitud(peritaje: number, contacto: number, valor: number = 0) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/setSolicitud.php`, {
      peritaje: peritaje,
      contacto: contacto,
      valor: valor
    })
  }

  getSolicitudByPeritajeContacto(peritaje: number, contacto: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getSolicitudByPeritajeContacto.php`, {
      peritaje: peritaje,
      contacto: contacto,
    })
  }

  getSolicitudByContacto(contacto: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getSolicitudByContacto.php`, {
      contacto: contacto,
    })
  }

  getSolicitudesByContactoParams(contacto: number, search: string, fecha_inicio: string | undefined, fecha_fin: string | undefined) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getSolicitudesByContactoParams.php`, {
      contacto: contacto,
      search: search,
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin
    })
  }

  getListadoSolicitudes(email: string, type: string, state: boolean) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getListadoSolicitudes.php`, {
      email: email,
      type: type,
      state: state
    })
  }

  getListadoSolicitudesByParams(type: number, especialidad: number, subespecialidad: number, ubicacion: string, acepta_encargos: boolean) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getListadoSolicitudesByParams.php`, {
      type: type,
      especialidad: especialidad,
      subespecialidad: subespecialidad,
      ubicacion: ubicacion,
      acepta_encargos: acepta_encargos,
    })
  }

  getDashboardSolicitudes(email: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getDashboardSolicitudes.php`, {
      email: email,
    })
  }

  getListadoUsuarios(email: string, type: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getListadoUsuarios.php`, {
      email: email,
      type: type,
    })
  }

  deleteProfessional(id: number, email: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/deleteUsuario.php`, {
      id: id,
      email: email,
    })
  }

  deleteSolicitud(id: number, email: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/deleteSolicitud.php`, {
      id: id,
      email: email,
    })
  }

  updateContrato(solicitud: number, contacto: number, contrato: boolean) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/updateContratar.php`, {
      solicitud: solicitud,
      contacto: contacto,
      contrato: contrato
    })
  }

  getDatosFacturacion(email: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getDatosFacturacion.php`, {
      email: email,
    });
  }

  getDocumentacion(solicitud: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/getDocumentacion.php`, {
      solicitud: solicitud
    }, { responseType: 'blob' })
  }

  hasDocumentacion(solicitud: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/hasDocumentacion.php`, {
      solicitud: solicitud
    })
  }

  addValoracion(email: string, valor: string, perito: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/addValoracion.php`, {
      email: email,
      valor: valor,
      perito: perito
    })
  }
}
